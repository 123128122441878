import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import validator from 'validator';
import { NetlifyForm, Honeypot } from 'react-netlify-forms';
import { event } from 'react-ga';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

import check from '../../images/check-mark.svg';
import clip from '../../images/clip.svg';

/**
 * Jobs page Form component.
 * @component
 *
 * @param   {string} position  - job position name
 * @returns {component}        - <Form position={string} />
 */

const Form = ({ position }) => {
  const file = useRef();
  // eslint-disable-next-line no-unused-vars
  const [formName, setFormName] = useState('Job Application');
  const [data, setData] = useState({
    fileInput: null,
    fileObj: null,
    fileSelected: false,
  });
  const [sent, setSent] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const validateEmail = evt => {
    const email = evt.target.value;

    if (email === '' || validator.isEmail(email)) {
      setInvalidEmail(false);
    } else {
      setInvalidEmail(true);
    }
  };

  const handleKeyPress = evt => {
    const el = evt.target;
    if (el.value.length === 3 || el.value.length === 7) {
      el.value += '-';
    }
  };
  // set file metadata
  const updateFile = evt => {
    evt.preventDefault();

    setData({
      ...data,
      fileInput: file.current.files[0].name,
      fileObj: file.current.files[0],
      fileSelected: !file.current.fileSelected,
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSent(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (sent) {
      event({
        category: 'Form',
        action: 'Submission',
        label: formName,
      });
    }
  }, [sent]);

  return (
    <section
      id="apply"
      className={cx(
        base.flex,
        base.itemsCenter,
        base.justifyCenter,
        base.w100,
        main.jobFormWrap,
        main.px60
      )}
    >
      <div
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsStart,
          base.justifyCenter,
          base.w100,
          main.jobForm
        )}
      >
        <h3 className={cx(main.mb60, theme.colorDark)}>
          Start Your Application
        </h3>
        <NetlifyForm
          acceptCharset="UTF-8"
          name={`${formName} - ${position}`}
          encType="multipart/form-data"
          className={cx(
            base.flex,
            base.flexColumn,
            base.itemsStart,
            base.justifyStart,
            base.w100
          )}
          honeypotName="bot-field"
        >
          {({ handleChange, success, submitting }) => {
            if (success) {
              setSent(true);
            }

            return (
              <>
                <Honeypot />
                <input
                  id="position"
                  name="position"
                  type="hidden"
                  value={position}
                />
                <div
                  className={cx(
                    base.flex,
                    base.flexWrap,
                    base.itemsCenter,
                    base.justifyStart,
                    base.mb3,
                    base.pb2,
                    base.w100
                  )}
                >
                  <label
                    htmlFor="name"
                    className={cx(
                      base.ma0,
                      base.mr4,
                      theme.colorTextGrey,
                      theme.title3,
                      main.formInputLabel
                    )}
                  >
                    Full Name<span className={theme.colorDanger}> *</span>
                  </label>
                  <input
                    id="name"
                    data-testid="name"
                    className={cx(
                      base.w100,
                      main.formInputField,
                      main.formInputFieldJob
                    )}
                    type="text"
                    name="name"
                    inputMode="text"
                    maxLength="80"
                    required
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={cx(
                    base.flex,
                    base.flexWrap,
                    base.itemsCenter,
                    base.justifyStart,
                    base.mb3,
                    base.pb2,
                    base.relative,
                    base.w100
                  )}
                >
                  <label
                    htmlFor="email"
                    className={cx(
                      base.ma0,
                      base.mr4,
                      theme.colorTextGrey,
                      theme.title3,
                      main.formInputLabel
                    )}
                  >
                    Email<span className={theme.colorDanger}> *</span>
                  </label>
                  <input
                    id="email"
                    data-testid="email"
                    className={cx(
                      base.w100,
                      main.formInputField,
                      main.formInputFieldJob
                    )}
                    type="text"
                    name="email"
                    inputMode="email"
                    required
                    onChange={evt => {
                      handleChange(evt);
                      validateEmail(evt);
                    }}
                    data-valid={`${!invalidEmail}`}
                  />
                  <span
                    className={cx(main.emailValidationError)}
                    aria-hidden={!invalidEmail}
                    style={{ display: invalidEmail ? 'block' : 'none' }}
                  >
                    Invalid Email
                  </span>
                </div>
                <div
                  className={cx(
                    base.flex,
                    base.flexWrap,
                    base.itemsCenter,
                    base.justifyStart,
                    base.mb3,
                    base.pb2,
                    base.w100
                  )}
                >
                  <label
                    htmlFor="phone"
                    className={cx(
                      base.ma0,
                      base.mr4,
                      theme.colorTextGrey,
                      theme.title3,
                      main.formInputLabel
                    )}
                  >
                    Phone
                  </label>
                  <input
                    id="phone"
                    className={cx(
                      base.w100,
                      main.formInputField,
                      main.formInputFieldJob
                    )}
                    type="tel"
                    name="phone"
                    inputMode="phone"
                    maxLength="12"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                  />
                </div>
                <div
                  className={cx(
                    base.flex,
                    base.flexWrap,
                    base.itemsCenter,
                    base.justifyStart,
                    base.mb3,
                    base.pb2,
                    base.w100
                  )}
                >
                  <label
                    htmlFor="linkedin"
                    className={cx(
                      base.ma0,
                      base.mr4,
                      theme.colorTextGrey,
                      theme.title3,
                      main.formInputLabel
                    )}
                  >
                    LinkedIn URL
                  </label>
                  <input
                    id="linkedin"
                    className={cx(
                      base.w100,
                      main.formInputField,
                      main.formInputFieldJob
                    )}
                    type="text"
                    name="linkedin"
                    inputMode="url"
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={cx(
                    base.flex,
                    base.flexWrap,
                    base.itemsCenter,
                    base.justifyStart,
                    base.mb3,
                    base.pb2,
                    base.w100
                  )}
                >
                  <label
                    htmlFor="website"
                    className={cx(
                      base.ma0,
                      base.mr4,
                      theme.colorTextGrey,
                      theme.title3,
                      main.formInputLabel
                    )}
                  >
                    Website URL
                  </label>
                  <input
                    id="website"
                    className={cx(
                      base.w100,
                      main.formInputField,
                      main.formInputFieldJob
                    )}
                    type="text"
                    name="website"
                    inputMode="url"
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={cx(
                    base.flex,
                    base.itemsCenter,
                    base.justifyStart,
                    base.mb3,
                    base.pb2,
                    base.relative,
                    base.w100,
                    main.formFileWrap
                  )}
                >
                  <label
                    htmlFor="resume"
                    className={cx(
                      base.flex,
                      base.itemsCenter,
                      base.justifyBetween,
                      base.ma0,
                      base.w100,
                      base.z5,
                      theme.colorTextGrey,
                      theme.title3,
                      main.formFileLabel
                    )}
                  >
                    Resume / CV
                    <input
                      id="resume"
                      className={cx(
                        base.w100,
                        main.formInputField,
                        main.formInputFile
                      )}
                      type="file"
                      name="resume"
                      accept=".pdf,.doc,.docx"
                      ref={file}
                      onChange={evt => {
                        updateFile(evt);
                        handleChange(evt);
                      }}
                    />
                  </label>
                  <p
                    className={cx(
                      base.absolute,
                      base.bottom0,
                      base.flex,
                      base.itemsCenter,
                      base.justifyCenter,
                      base.ma0,
                      base.right0,
                      base.tc,
                      base.top0,
                      base.w100,
                      base.z1,
                      main.formFileOverlay,
                      theme.colorTextGrey,
                      theme.shapeWhite,
                      theme.title3
                    )}
                    aria-hidden="true"
                  >
                    {/* styled doc picker UI overlay */}
                    {!data.fileSelected && (
                      <img
                        src={clip}
                        alt=""
                        aria-hidden="true"
                        className={main.formFileIcon}
                      />
                    )}
                    <span
                      className={cx(
                        base.overflowHidden,
                        main.formFileOverlayCopy
                      )}
                    >
                      {data.fileSelected ? data.fileInput : 'Attach Resume'}
                    </span>
                  </p>
                </div>
                {sent ? (
                  <div
                    data-testid="confirmation"
                    className={cx(
                      base.flex,
                      base.itemsCenter,
                      base.justifyCenter,
                      main.jobFormConfirmation
                    )}
                  >
                    <img
                      src={check}
                      alt=""
                      aria-hidden="true"
                      className={main.jobFormCheck}
                    />
                    <p
                      className={cx(
                        base.ma0,
                        base.tc,
                        base.w100,
                        theme.colorTextGrey
                      )}
                    >
                      Thank you for your submission!
                    </p>
                  </div>
                ) : (
                  <button
                    type="submit"
                    data-testid="submit"
                    className={cx(
                      base.w100,
                      main.button,
                      main.jobFormButton,
                      main.mt30,
                      theme.colorWhite,
                      theme.dpBtnBlue,
                      theme.title3
                    )}
                    disabled={submitting || sent || invalidEmail}
                  >
                    {submitting ? 'Submiting' : 'Submit Application'}
                  </button>
                )}
              </>
            );
          }}
        </NetlifyForm>
      </div>
    </section>
  );
};

Form.propTypes = {
  position: PropTypes.string,
};

Form.defaultProps = {
  position: '',
};

export default Form;
