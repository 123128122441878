import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import cx from 'classnames';

import base from '../styles/base.module.css';
import main from '../styles/main.module.css';
import theme from '../styles/theme.module.css';

import CleanHTML from '../components/cleanHTML';
import Form from '../components/jobs/jobApplication';
import Layout from '../components/layout';

// template query used to generate static pages
export const query = graphql`
  query JobQuery($slug: String!) {
    contentfulJobOpenings(slug: { eq: $slug }) {
      title
      type
      where
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;

/**
 * Jobs page Opening template component.
 * @component
 *
 * @param   {object} data     - job opening copy and info object
 * @param   {string} location - URL data object
 * @returns {component}       - <Opening data={object} location={string} />
 */

const Opening = ({ data, location }) => {
  const {
    contentfulJobOpenings: {
      body: {
        childMarkdownRemark: { html },
      },
      where,
      title,
      type,
    },
  } = data;

  return (
    <Layout pageTitle={title} location={location}>
      <article
        className={cx(
          base.w100,
          main.mb120,
          main.mt30,
          main.postSingle,
          main.px60
        )}
      >
        <section className={cx(main.jobsHeading, main.mb60)}>
          <h1 className={cx(base.mb3, theme.colorDark, theme.title0)}>
            {title}
          </h1>
          <div
            className={cx(
              base.flex,
              base.flexWrap,
              base.itemsCenter,
              base.justifyBetween,
              base.w100
            )}
          >
            <div
              className={cx(
                base.flex,
                base.itemsCenter,
                base.justifyStart,
                base.mb2,
                base.mt2
              )}
            >
              <p
                className={cx(
                  base.mb0,
                  base.mr4,
                  theme.colorTextGrey,
                  theme.title2
                )}
              >
                {where}
              </p>
              <p className={cx(base.mb0, theme.colorTextGrey, theme.title2)}>
                {type}
              </p>
            </div>
            <a
              href="#apply"
              className={cx(
                main.button,
                main.jobButton,
                theme.colorBlue,
                theme.shapeWhite
              )}
            >
              Apply Now
            </a>
          </div>
        </section>
        <section className={cx(main.pt60, main.jobPostBody, main.postBody)}>
          <CleanHTML html={html} />
        </section>
      </article>
      <Form position={title} />
    </Layout>
  );
};

Opening.propTypes = {
  data: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    contentfulJobOpenings: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default Opening;
